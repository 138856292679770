import { default as consultancyroYIGgkc5wMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/consultancy.vue?macro=true";
import { default as contactqKIrUIZxtXMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/contact.vue?macro=true";
import { default as designtRnGp0TpNcMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/design.vue?macro=true";
import { default as indexTt0XAapogOMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/index.vue?macro=true";
import { default as our_45locationsj6gaoekG1xMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/our-locations.vue?macro=true";
import { default as services5lKqwu9q8zMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/services.vue?macro=true";
import { default as technology2S3gPOyjDlMeta } from "/home/runner/work/mirasoft.tech/mirasoft.tech/pages/technology.vue?macro=true";
export default [
  {
    name: "consultancy",
    path: "/consultancy",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/consultancy.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "our-locations",
    path: "/our-locations",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/services.vue").then(m => m.default || m)
  },
  {
    name: "technology",
    path: "/technology",
    component: () => import("/home/runner/work/mirasoft.tech/mirasoft.tech/pages/technology.vue").then(m => m.default || m)
  }
]