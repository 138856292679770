
import * as ipxRuntime$9wCrcMRP8w from '/home/runner/work/mirasoft.tech/mirasoft.tech/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$FMIXOKNGDN from '/home/runner/work/mirasoft.tech/mirasoft.tech/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$9wCrcMRP8w, defaults: {} },
  ['ipxStatic']: { provider: ipxStaticRuntime$FMIXOKNGDN, defaults: {} }
}
        