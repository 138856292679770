<template>
  <div class="w-[100vw] h-[100vh] relative bg-black">
    <div class="circle absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div v-for="i in 6" :key="i" class="dot">
        <nuxt-picture src="/images/loading/vector.svg" alt="Loading" width="100%" height="100%" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.circle {
  position: relative;
  width: 150px;
  height: 150px;
}

.dot {
  position: absolute;
  width: 25px;
  height: 25px;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
  transform-origin: 12.5px 12.5px;
  opacity: 0;
  animation: dot 2s linear infinite;
}

.dot:nth-child(1) {
  transform: rotate(0deg) translateY(-55px);
  animation-delay: 0s;
}

.dot:nth-child(2) {
  transform: rotate(60deg) translateY(-55px);
  animation-delay: 0.33s;
}

.dot:nth-child(3) {
  transform: rotate(120deg) translateY(-55px);
  animation-delay: 0.66s;
}

.dot:nth-child(4) {
  transform: rotate(180deg) translateY(-55px);
  animation-delay: 1s;
}

.dot:nth-child(5) {
  transform: rotate(240deg) translateY(-55px);
  animation-delay: 1.33s;
}

.dot:nth-child(6) {
  transform: rotate(300deg) translateY(-55px);
  animation-delay: 1.66s;
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.75;
  }
  80%,
  100% {
    opacity: 1;
  }
}
</style>
