<script setup>
import AOS from "aos";
import "aos/dist/aos.css";
import { scrollToTitle } from "@/composables/commonFunction.js";

const visible = ref(false);
const collapseProduct = ref(false);
const visibleDesign = ref(false);
const collapseProductDesign = ref(false);
const visibleServices = ref(false);
const collapseProductServices = ref(false);
const visibleConsultancy = ref(false);
const collapseProductConsultancy = ref(false);
const visibleTechnology = ref(false);
const collapseProductTechnology = ref(false);
const domain = "https://outsource.qrs.vn";
const route = useRoute();

useHead({
  link: [{ rel: "canonical", href: domain + route.path }],
  meta: [
    { name: "og:url", content: domain + route.path },
    { name: "og:type", content: "website" },
    { name: "og:image", content: domain + "/images/our-clients-bg.jpg" },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:image", content: domain + "/images/our-clients-bg.jpg" },
  ],
});

watch(
  route,
  () => {
    if (visible.value) {
      return (visible.value = false);
    }
    if (visibleDesign.value) {
      return (visibleDesign.value = false);
    }
    if (visibleServices.value) {
      return (visibleServices.value = false);
    }
    if (visibleConsultancy.value) {
      return (visibleConsultancy.value = false);
    }
    if (visibleTechnology.value) {
      return (visibleTechnology.value = false);
    }
  },
  { deep: true },
);

onMounted(() => {
  AOS.init({
    duration: 1000,
    once: true,
  });
});

const isLoading = ref(false);
onMounted(() => {
  isLoading.value = true;
  setTimeout(() => (isLoading.value = false), 2000);
});
</script>

<template>
  <Loading v-if="isLoading" />
  <div class="min-h-[100vh]" v-else>
    <header>
      <div class="flex-1 flex gap-x-[40px] items-center">
        <nuxt-link to="/">
          <nuxt-picture
            loading="lazy"
            src="/images/logo-1.svg"
            :img-attrs="{ alt: 'logo' }"
            width="122"
            class="max-xl:hidden"
          />
          <nuxt-picture
            loading="lazy"
            src="/images/logo-only.svg"
            :img-attrs="{ alt: 'logo' }"
            width="40"
            class="hidden max-xl:block"
          />
        </nuxt-link>
        <div class="nav-menu flex gap-x-[50px] max-xl:hidden">
          <nuxt-link to="/" class="hover:text-[#ed7f22] ml-5 uppercase font-semibold">Home</nuxt-link>
          <div class="relative hover:text-[#ed7f22] font-semibold">
            <nuxt-link to="/services" class="hover:text-[#ed7f22] uppercase font-semibold">SERVICES</nuxt-link>

            <div class="sub-menu hidden border-2 border-black bg-[rgba(0,0,0,0.9)] absolute top-[100%] left-0 pt-0">
              <div class="border-2 w-[180px] my-5"></div>
              <nuxt-link
                to="/services"
                @click="scrollToTitle('consultancy')"
                class="text-[12px] !text-white sub-menu-item hover:!text-[#ed7f22]"
              >
                Consultancy
              </nuxt-link>
              <nuxt-link
                to="/services"
                @click="scrollToTitle('technology')"
                class="text-[12px] !text-white sub-menu-item hover:!text-[#ed7f22]"
              >
                Technology
              </nuxt-link>
              <nuxt-link
                to="/services"
                @click="scrollToTitle('managed-services')"
                class="text-[12px] !text-white sub-menu-item hover:!text-[#ed7f22]"
              >
                Managed Services
              </nuxt-link>
              <nuxt-link
                to="/services"
                @click="scrollToTitle('design')"
                class="text-[12px] !text-white sub-menu-item hover:!text-[#ed7f22]"
              >
                Design
              </nuxt-link>
            </div>
          </div>
          <div class="relative hover:text-[#ed7f22] font-semibold">
            CASE STUDIES
            <div class="navbar hidden absolute top-[100%] left-0 pt-5">
              <div class="sub-menu-item">
                <nuxt-link
                  to="/consultancy"
                  class="subtitle-3 font-semibold uppercase !text-white hover:!text-[#ed7f22]"
                >
                  Consultancy
                </nuxt-link>

                <hr class="border-2 w-[161px] my-5" />
                <nuxt-link
                  to="/consultancy"
                  @click="scrollToTitle('it-consulting')"
                  class="text-white normal-case text-[12px] font-normal hover:text-[#ed7f22] block"
                >
                  IT Consulting
                </nuxt-link>
                <nuxt-link
                  to="/consultancy"
                  @click="scrollToTitle('it-services')"
                  class="text-white normal-case py-4 text-[12px] font-normal hover:text-[#ed7f22] block"
                >
                  IT Services
                </nuxt-link>
              </div>
              <div class="sub-menu-item">
                <nuxt-link
                  to="/technology"
                  class="subtitle-3 font-semibold uppercase !text-white hover:!text-[#ed7f22]"
                >
                  Technology
                </nuxt-link>
                
                <hr class="border-2 w-[161px] my-5" />
                <nuxt-link
                  to="/technology"
                  class="text-white normal-case text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('artificial-intelligence-ai')"
                >
                  Artificial Intelligence (AI)
                </nuxt-link>
                <nuxt-link
                  to="/technology"
                  class="text-white normal-case py-5 text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('augmented-reality-ar')"
                >
                  Augmented Reality (AR)
                </nuxt-link>
                <nuxt-link
                  to="/technology"
                  class="text-white normal-case pb-5 text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('amr-agv')"
                >
                  AMR/AGV
                </nuxt-link>
                <nuxt-link
                  to="/technology"
                  class="text-white normal-case pb-5 text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('internet-of-things-iot')"
                >
                  Internet of Things (IoT)
                </nuxt-link>
                <nuxt-link
                  to="/technology"
                  class="text-white normal-case pb-5 text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('blockchain')"
                >
                  Blockchain
                </nuxt-link>
              </div>
              <div class="sub-menu-item">
                <nuxt-link
                  to="/design"
                  class="subtitle-3 font-semibold uppercase !text-white hover:!text-[#ed7f22]"
                >
                  Design
                </nuxt-link>

                <hr class="border-2 w-[161px] my-5" />
                <nuxt-link
                  to="/design"
                  class="text-white normal-case text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('ui-ux-design')"
                >
                  UI/UX Design
                </nuxt-link>
                <nuxt-link
                  to="/design"
                  class="text-white normal-case text-[12px] font-normal py-5 hover:text-[#ed7f22] block"
                  @click="scrollToTitle('e-commerce-web-design')"
                >
                  E-commerce Web Design
                </nuxt-link>
                <nuxt-link
                  to="/design"
                  class="text-white normal-case text-[12px] font-normal hover:text-[#ed7f22] block"
                  @click="scrollToTitle('ui-packaging')"
                >
                  UI & Packaging
                </nuxt-link>
              </div>
            </div>
          </div>
          <nuxt-link to="/our-locations" class="hover:text-[#ed7f22] uppercase font-semibold">Our Locations</nuxt-link>
        </div>
      </div>
      <div class="flex gap-x-4 items-center max-xl:hidden">
        <nuxt-link to="/contact" aria-label="get start">
          <button class="primary small shadow-[0px_2px_0px_0px_#0000000B] uppercase" aria-label="login">
            Contact us
          </button>
        </nuxt-link>
        <nuxt-link
          to="/contact"
          class="w-[56px] h-[56px] rounded-[50%] bg-[#F67D30] flex justify-center items-center"
          aria-label="arrown down"
        >
          <nuxt-picture
            loading="lazy"
            src="/images/arrow-down.svg"
            :img-attrs="{ alt: 'arrown-down', width: 28, height: 28 }"
            class="font-extrabold"
          />
        </nuxt-link>
      </div>

      <button class="!px-2 border-none xl:hidden" @click="visible = true" aria-label="icon-3-dot">
        <svg viewBox="0 -53 384 384" width="28px" height="28px" class="fill-[#ffff]">
          <path
            d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
          <path
            d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
          <path
            d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
        </svg>
      </button>

      <a-drawer
        v-model:visible="visible"
        :bodyStyle="{ paddingTop: '45px' }"
        :contentWrapperStyle="{ width: '100vw' }"
        body
        :closable="false"
        placement="right"
      >
        <template #title>
          <div class="flex items-center justify-between bg-black">
            <nuxt-link to="/">
              <nuxt-picture loading="lazy" src="/images/logo-1.svg" :img-attrs="{ alt: 'logo' }" width="122" />
            </nuxt-link>

            <button class="small !px-2 border-none" @click="visible = false" aria-label="collapse-icon">
              <svg viewBox="0 0 329.26933 329" width="20px" height="20px" class="fill-white">
                <path
                  d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
                />
              </svg>
            </button>
          </div>
        </template>

        <div class="py-3 px-2 hover:text-[#ed7f22] uppercase font-bold">
          <nuxt-link to="/" @click="visible = false">Home</nuxt-link>
        </div>

        <div class="py-0 px-2 hover:text-[#ed7f22] uppercase">
          <div
            class="py-3 border-solid border-b border-[#E0E0E0] flex items-center justify-between uppercase font-bold"
          >
            <nuxt-link to="/services" @click="visible = false">SERVICES</nuxt-link>

            <svg
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
              class="fill-[black] transition-all"
              :class="{ 'rotate-180 ': !collapseProductServices }"
              @click="collapseProductServices = !collapseProductServices"
            >
              <g id="_16" data-name="16">
                <path
                  d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
                />
              </g>
            </svg>
          </div>

          <div
            class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
            :class="{ 'h-0 !py-0': collapseProductServices }"
          >
            <nuxt-link
              to="/services"
              class="sub-menu-item normal-case subtitle-4 text-primary font-semibold"
              @click="
                scrollToTitle('consultancy');
                visible = false;
              "
            >
              Consultancy
            </nuxt-link>
            <nuxt-link
              to="/services"
              class="sub-menu-item normal-case subtitle-4 text-primary font-semibold"
              @click="
                scrollToTitle('technology');
                visible = false;
              "
            >
              Technology
            </nuxt-link>
            <nuxt-link
              to="/services"
              class="sub-menu-item normal-case subtitle-4 text-primary font-semibold"
              @click="
                scrollToTitle('managed-services');
                visible = false;
              "
            >
              managed-services
            </nuxt-link>
            <nuxt-link
              to="/services"
              class="sub-menu-item normal-case subtitle-4 text-primary font-semibold"
              @click="
                scrollToTitle('design');
                visible = false;
              "
            >
              Design
            </nuxt-link>
          </div>
        </div>

        <div class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between uppercase">
          <span class="font-bold">Case studies</span>

          <svg
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
            class="fill-black transition-all"
            :class="{ 'rotate-180 ': !collapseProduct }"
            @click="collapseProduct = !collapseProduct"
          >
            <g id="_16" data-name="16">
              <path
                d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
              />
            </g>
          </svg>
        </div>

        <div
          class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
          :class="{ 'h-0 !py-0': collapseProduct }"
        >
          <div
            class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between uppercase"
            @click="collapseProductConsultancy = !collapseProductConsultancy"
          >
            <nuxt-link
              to="/consultancy"
              class="sub-menu-item subtitle-4 text-primary uppercase font-semibold"
              @click="
                visibleConsultancy = false;
                visible = false;
              "
            >
              Consultancy
            </nuxt-link>

            <svg
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
              class="fill-black transition-all"
              :class="{ 'rotate-180 ': !collapseProductConsultancy }"
            >
              <g id="_16" data-name="16">
                <path
                  d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
                />
              </g>
            </svg>
          </div>
          <div
            class="sub-menu flex flex-col gap-y-4 px-2 overflow-hidden transition-all"
            :class="{ 'h-0 !py-0': collapseProductConsultancy }"
          >
            <nuxt-link
              to="/consultancy"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('it-consulting');
                visible = false;
              "
            >
              IT Consulting
            </nuxt-link>
            <nuxt-link
              to="/consultancy"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('it-services');
                visible = false;
              "
            >
              IT Services
            </nuxt-link>
          </div>
          <div
            class="px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between uppercase"
            @click="collapseProductTechnology = !collapseProductTechnology"
          >
            <nuxt-link
              to="/technology"
              class="sub-menu-item subtitle-4 text-primary uppercase font-semibold"
              @click="
                visibleTechnology = false;
                visible = false;
              "
            >
              Technology
            </nuxt-link>

            <svg
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
              class="fill-black transition-all"
              :class="{ 'rotate-180 ': !collapseProductTechnology }"
            >
              <g id="_16" data-name="16">
                <path
                  d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
                />
              </g>
            </svg>
          </div>
          <div
            class="sub-menu flex flex-col gap-y-4 px-2 overflow-hidden transition-all"
            :class="{ 'h-0 !py-0': collapseProductTechnology }"
          >
            <nuxt-link
              to="/technology"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('artificial-intelligence-ai');
                visible = false;
              "
            >
              Artificial Intelligence (AI)
            </nuxt-link>
            <nuxt-link
              to="/technology"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('augmented-reality-ar');
                visible = false;
              "
            >
              Augmented Reality (AR)
            </nuxt-link>
            <nuxt-link
              to="/technology"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('amr-agv');
                visible = false;
              "
            >
              AMR/AGV
            </nuxt-link>
            <nuxt-link
              to="/technology"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('internet-of-things-iot');
                visible = false;
              "
            >
              Internet of Things (IoT)
            </nuxt-link>
            <nuxt-link
              to="/technology"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('blockchain');
                visible = false;
              "
            >
              Blockchain
            </nuxt-link>
          </div>
          <div
            class="px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between uppercase"
            @click="collapseProductDesign = !collapseProductDesign"
          >
            <nuxt-link
              to="/design"
              class="sub-menu-item subtitle-4 text-primary uppercase font-semibold"
              @click="
                visibleDesign = false;
                visible = false;
              "
            >
              Design
            </nuxt-link>

            <svg
              viewBox="0 0 24 24"
              width="20px"
              height="20px"
              class="fill-black transition-all"
              :class="{ 'rotate-180 ': !collapseProductDesign }"
            >
              <g id="_16" data-name="16">
                <path
                  d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
                />
              </g>
            </svg>
          </div>
          <div
            class="sub-menu flex flex-col gap-y-4 px-2 overflow-hidden transition-all"
            :class="{ 'h-0 !py-0': collapseProductDesign }"
          >
            <nuxt-link
              to="/design"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('ui-ux-design');
                visible = false;
              "
            >
              UI/UX Design
            </nuxt-link>
            <nuxt-link
              to="/design"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('e-commerce-web-design');
                visible = false;
              "
            >
              E-commerce Web Design
            </nuxt-link>
            <nuxt-link
              to="/design"
              class="subtitle-4 hover:text-[#ed7f22]"
              @click="
                scrollToTitle('ui-packaging');
                visible = false;
              "
            >
              UI & Packaging
            </nuxt-link>
          </div>
        </div>
        <div class="py-3 px-2">
          <nuxt-link to="/our-locations" :external="true" class="hover:text-[#ed7f22] uppercase font-semibold"
            >Our Locations</nuxt-link
          >
        </div>
        <div class="py-3 px-2 hover:text-[#ed7f22] uppercase font-semibold">
          <nuxt-link to="/contact" @click="visible = false">CONTACt us</nuxt-link>
        </div>

        <div class="flex-center max-sm:flex-col gap-4 mt-4">
          <nuxt-link to="/contact" class="flex-1 w-full">
            <button class="primary large w-full uppercase" aria-label="login" @click="visible = false">
              Get started
            </button>
          </nuxt-link>
        </div>
      </a-drawer>
    </header>

    <main class="page-content bg-black max-xl:pt-0 mt-[80px] max-xl:mt-[64px] overflow-hidden">
      <NuxtPage />
    </main>

    <footer class="bg-black">
      <div class="flex max-lg:flex-wrap gap-[40px] justify-between mt-[40px]">
        <div>
          <nuxt-picture loading="lazy" src="/images/logo-1.svg" :img-attrs="{ alt: 'logo' }" width="232px" />
          <div id="company-info" class="max-lg:w-full mt-6">
            <div id="HN-address">
              <div class="max-w-[496px] flex gap-2 items-center">
                <div class="flex-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_356_995)">
                      <path
                        d="M11.9996 0.0424805C9.3535 0.0453916 6.81663 1.09776 4.94549 2.96871C3.07435 4.83966 2.02173 7.37642 2.01855 10.0225C2.01855 12.5925 4.00855 16.6145 7.93355 21.9765C8.40081 22.6166 9.01261 23.1374 9.71916 23.4964C10.4257 23.8554 11.207 24.0425 11.9996 24.0425C12.7921 24.0425 13.5734 23.8554 14.28 23.4964C14.9865 23.1374 15.5983 22.6166 16.0656 21.9765C19.9906 16.6145 21.9806 12.5925 21.9806 10.0225C21.9774 7.37642 20.9248 4.83966 19.0536 2.96871C17.1825 1.09776 14.6456 0.0453916 11.9996 0.0424805ZM11.9996 14.0005C11.2084 14.0005 10.4351 13.7659 9.77727 13.3264C9.11948 12.8868 8.60679 12.2621 8.30404 11.5312C8.00129 10.8003 7.92207 9.99605 8.07641 9.22012C8.23075 8.4442 8.61172 7.73146 9.17113 7.17205C9.73054 6.61264 10.4433 6.23168 11.2192 6.07734C11.9951 5.923 12.7994 6.00221 13.5303 6.30496C14.2612 6.60771 14.8859 7.1204 15.3254 7.7782C15.765 8.436 15.9996 9.20936 15.9996 10.0005C15.9996 11.0613 15.5781 12.0788 14.828 12.8289C14.0778 13.5791 13.0604 14.0005 11.9996 14.0005Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_356_995">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="subtitle-3 !text-white opacity-70">
                  Headquarters: 4th Floor - GTC Building, No. 69, To Huu Street, Van Phuc, Ha Dong, Hanoi
                </div>
              </div>
            </div>

            <div id="HCM-address" class="mt-3">
              <div class="max-w-[496px] flex gap-2 items-center">
                <div class="flex-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_356_995)">
                      <path
                        d="M11.9996 0.0424805C9.3535 0.0453916 6.81663 1.09776 4.94549 2.96871C3.07435 4.83966 2.02173 7.37642 2.01855 10.0225C2.01855 12.5925 4.00855 16.6145 7.93355 21.9765C8.40081 22.6166 9.01261 23.1374 9.71916 23.4964C10.4257 23.8554 11.207 24.0425 11.9996 24.0425C12.7921 24.0425 13.5734 23.8554 14.28 23.4964C14.9865 23.1374 15.5983 22.6166 16.0656 21.9765C19.9906 16.6145 21.9806 12.5925 21.9806 10.0225C21.9774 7.37642 20.9248 4.83966 19.0536 2.96871C17.1825 1.09776 14.6456 0.0453916 11.9996 0.0424805ZM11.9996 14.0005C11.2084 14.0005 10.4351 13.7659 9.77727 13.3264C9.11948 12.8868 8.60679 12.2621 8.30404 11.5312C8.00129 10.8003 7.92207 9.99605 8.07641 9.22012C8.23075 8.4442 8.61172 7.73146 9.17113 7.17205C9.73054 6.61264 10.4433 6.23168 11.2192 6.07734C11.9951 5.923 12.7994 6.00221 13.5303 6.30496C14.2612 6.60771 14.8859 7.1204 15.3254 7.7782C15.765 8.436 15.9996 9.20936 15.9996 10.0005C15.9996 11.0613 15.5781 12.0788 14.828 12.8289C14.0778 13.5791 13.0604 14.0005 11.9996 14.0005Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_356_995">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="subtitle-3 !text-white opacity-70">
                  Office in HCM: 211, 9A Trung Son Street, Binh Hung Ward, Binh Chanh District, Ho Chi Minh City,
                  Vietnam
                </div>
              </div>
            </div>

            <div id="phone-number" class="flex gap-2 items-center mt-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path
                  d="M19.4513 14.6906L16.6578 11.8971C15.6602 10.8994 13.9642 11.2985 13.5651 12.5955C13.2658 13.4934 12.2681 13.9922 11.3702 13.7927C9.37492 13.2938 6.68124 10.6999 6.18241 8.60482C5.88311 7.70689 6.48171 6.70923 7.3796 6.40997C8.67656 6.01091 9.07563 4.31488 8.07797 3.31722L5.28452 0.523772C4.48639 -0.174591 3.28919 -0.174591 2.59083 0.523772L0.695277 2.41933C-1.20028 4.41465 0.894809 9.70225 5.58381 14.3913C10.2728 19.0803 15.5604 21.2752 17.5557 19.2798L19.4513 17.3842C20.1497 16.5861 20.1497 15.3889 19.4513 14.6906Z"
                  fill="white"
                />
              </svg>

              <div class="subtitle-3 !text-white opacity-70">Tel: +84 24 36 436 999</div>
            </div>

            <div id="phone-number" class="flex gap-2 items-center mt-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 13 20" fill="none">
                <path
                  d="M12.006 0.558082V0.556023C11.6521 0.200323 11.1712 0.000205934 10.6695 0H1.89048C0.847781 0.00339792 0.00339792 0.847781 0 1.89048V18.0419C0.000205935 18.5436 0.200323 19.0246 0.556024 19.3784C0.909356 19.7336 1.38949 19.9337 1.89048 19.9345H10.6715C11.1725 19.9337 11.6527 19.7336 12.006 19.3784C12.3612 19.0251 12.5612 18.545 12.562 18.044V1.89254C12.5612 1.39155 12.3612 0.911415 12.006 0.558082ZM6.28101 18.6515C5.99893 18.6515 5.77029 18.4228 5.77029 18.1408C5.77029 17.8587 5.99893 17.6301 6.28101 17.6301C6.56309 17.6301 6.79172 17.8587 6.79172 18.1408C6.79172 18.4228 6.56309 18.6515 6.28101 18.6515ZM11.0607 15.4451C11.0607 16.1275 10.5076 16.6807 9.82514 16.6807H2.73481C2.0524 16.6807 1.4992 16.1275 1.4992 15.4451V3.40822C1.4992 2.7258 2.0524 2.17261 2.73481 2.17261H9.8272C10.5096 2.17261 11.0628 2.7258 11.0628 3.40822L11.0607 15.4451Z"
                  fill="white"
                />
              </svg>

              <div class="subtitle-3 !text-white opacity-70">Hotline : +84 935 10 3636</div>
            </div>

            <div id="email" class="flex gap-2 items-center mt-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                <g clip-path="url(#clip0_356_1012)">
                  <path
                    d="M23.954 5.34912L15.536 12.7149C14.5974 13.5341 13.3257 13.9942 12 13.9942C10.6743 13.9942 9.40263 13.5341 8.464 12.7149L0.046 5.34912C0.032 5.48737 0 5.6125 0 5.74987V16.2499C0.00158786 17.4098 0.528882 18.5218 1.46622 19.3419C2.40356 20.1621 3.6744 20.6235 5 20.6249H19C20.3256 20.6235 21.5964 20.1621 22.5338 19.3419C23.4711 18.5218 23.9984 17.4098 24 16.2499V5.74987C24 5.6125 23.968 5.48737 23.954 5.34912Z"
                    fill="white"
                  />
                  <path
                    d="M14.1221 11.4775L23.2561 3.48438C22.8137 2.84241 22.1896 2.31104 21.4441 1.94152C20.6986 1.572 19.8569 1.3768 19.0001 1.37476H5.00014C4.14336 1.3768 3.30164 1.572 2.55617 1.94152C1.81069 2.31104 1.18662 2.84241 0.744141 3.48438L9.87814 11.4775C10.4417 11.9687 11.2047 12.2444 12.0001 12.2444C12.7956 12.2444 13.5586 11.9687 14.1221 11.4775V11.4775Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_356_1012">
                    <rect width="24" height="21" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              <div class="subtitle-3 !text-white opacity-70">contact@mirasoft.tech</div>
            </div>
          </div>
        </div>
        <div id="product" class="flex flex-col gap-y-6 max-lg:w-1/2 max-lg:flex-1">
          <div class="subtitle-2 !text-white uppercase">Company</div>
          <nuxt-link to="/services" class="subtitle-3 uppercase">services</nuxt-link>
          <nuxt-link to="/our-locations" class="subtitle-3 uppercase">Our Locations</nuxt-link>
          <nuxt-link to="/contact" class="subtitle-3 uppercase">Contact</nuxt-link>
        </div>

        <div id="QRX" class="flex flex-col gap-y-6 max-lg:w-1/2 max-lg:flex-1">
          <div class="subtitle-2 !text-white uppercase">Services</div>

          <nuxt-link
            to="/services"
            @click="
              scrollToTitle('consultancy');
              visible = false;
            "
            class="subtitle-3 uppercase"
            >Consultancy</nuxt-link
          >
          <nuxt-link
            to="/services"
            @click="
              scrollToTitle('technology');
              visible = false;
            "
            class="subtitle-3 uppercase"
            >Technology</nuxt-link
          >
          <nuxt-link
            to="/services"
            @click="
              scrollToTitle('managed services');
              visible = false;
            "
            class="subtitle-3 uppercase"
            >Managed Services</nuxt-link
          >
          <nuxt-link
            to="/services"
            @click="
              scrollToTitle('design');
              visible = false;
            "
            class="subtitle-3 uppercase"
            >Design</nuxt-link
          >
        </div>

        <div id="ho-tro" class="flex flex-col gap-y-6 max-lg:w-1/2 max-lg:flex-1">
          <div class="subtitle-2 !text-white uppercase">Contact</div>

          <nuxt-link to="/contact" class="subtitle-3 uppercase">CALL US</nuxt-link>
          <nuxt-link to="/contact" class="subtitle-3 uppercase">Chat with us</nuxt-link>
          <nuxt-link to="/contact" class="subtitle-3 uppercase">email us</nuxt-link>
        </div>

        <div id="follow-us" class="flex flex-col gap-y-6 max-lg:w-1/2 max-lg:flex-1">
          <div class="subtitle-2 !text-white uppercase">FOLLOW US</div>

          <div class="flex gap-2">
            <nuxt-link
              to="/"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-[#808080] rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'facebook' }" loading="lazy" src="/images/socials/faceboock-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="/"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-[#808080] rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'twiter' }" loading="lazy" src="/images/socials/what-app-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="/"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-[#808080] rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'linkerin' }" loading="lazy" src="/images/socials/linkerin-white.svg" />
            </nuxt-link>
          </div>
        </div>
      </div>

      <div class="border border-solid border-[#D9D9D9] mt-[56px]"></div>

      <div class="flex justify-between py-4">
        <small class="flex-1 text-[#EEEEEE] text-left opacity-70"
          >© Copyright – 2023 | MIRAWAY | All Rights Reserved</small
        >
        <small class="flex-1 text-[#EEEEEE] text-right opacity-70">Privacy & Policy</small>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.heading-title,
.picture {
  :deep(img) {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
